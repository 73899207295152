.no-wrap> .btn {
    display: inline-flex; /* Use inline-flex to make the icon and text align horizontally */
    align-items: center; /* Center the icon and text vertically */
}
  
.no-wrap> .btn{
    white-space: nowrap; /* Prevent the text from wrapping */
    overflow: hidden; /* Hide any overflowing text */
    text-overflow: ellipsis; /* Show an ellipsis for truncated text */
}
  
/* Hide the icon when the text overflows */
.no-wrap>.btn>svg {
    display: inline; /* Show the icon by default */
  
    /* Hide the icon when the text overflows */
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  /* Show the icon when there's enough space for both icon and text */
  @media screen and (min-width: 768px) {
    .no-wrap> .btn .btn>svg {
      display: inline;
    }
  }
  