$duration: 200ms;


.portal-fade {
    // transition: background-color, opacity $duration ease-in;
    animation: hide-load-anim $duration ease-in;
    display: block;
}

@keyframes hide-load-anim {
    from { 
        visibility: hidden;
    }
    to { 
        visibility: hidden;
    }
}

.portal-fade-show {
    background-color: transparent;
    animation: fade-in $duration ease-in forwards;
}

.portal-fade-hide {
    background-color: rgba(black, 0.2);
    animation: fade-out $duration ease-in forwards;
}

@keyframes fade-in {
    from { 
        display: block;
    }
    to { 
        display: block;
        background-color: rgba(black, 0.2);
    }
}

@keyframes fade-out {
    from { 
        display: block; 
        background-color: rgba(black, 0.2);
    }
    to { 
        display: none;
        background-color: transparent;
    }
}

.slide-in-left {
    transition: transform $duration ease-in-out;
    opacity: 1;
}

.slide-in-left-show {
    transform: translateX(0%);
}

.slide-in-left-hide {
    transform: translateX(100%);
}

.portal {
    width: 95%;
}

/* Use Bootstrap grid system for responsiveness */
@media (min-width: 576px) {
    /* Small devices (sm) */
    .portal {
        width: 90%;
    }
}

@media (min-width: 768px) {
    /* Medium devices (md) */
    .portal {
        width: 75%;
    }
}

@media (min-width: 992px) {
    /* Large devices (lg) */
    .portal {
        width: 50%;
    }
}

@media (min-width: 1200px) {
    /* Extra large devices (xl) */
    .portal {
        width: 35%;
    }
}