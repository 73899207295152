$color-primary: #61d345;
$border-radius: 0px 8px 8px 0px;

.insight-positive {
  border-left: 5px solid #61d345;
  border-radius: $border-radius;
}

.insight-neutral {
  border-left: 5px solid #3c64d3;
  border-radius: $border-radius;
}

.insight-negative {
  border-left: 5px solid #d31b11;
  border-radius: $border-radius;
}