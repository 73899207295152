@import '~bootstrap/scss/bootstrap.scss';

.breadcrumb {
    text-align: center;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    letter-spacing: 0.07px;

    padding-bottom: 0;
    margin-bottom: 0;

    > a, > span{
        color: #4B5563;
    }

    &.active{
        color: #1F2937;
    }
}

.breadcrumb:hover{
    > svg, > a{
        color: $primary;
    }
}