$indentWidth: 2em;

.indent-0 {
  // background-color: green!important;
  margin-left: 0;
  width: calc(100%);
}

.indent-1 {
  // background-color: yellow!important;
  margin-left: $indentWidth * 1;
  width: calc(100% - #{$indentWidth * 1});
}

.indent-2 {
  // background-color: red!important;
  margin-left: $indentWidth * 2;
  width: calc(100% - #{$indentWidth * 2});
}