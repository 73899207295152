// Import React-Bootstrap's default variables
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.more {
  display: flex;
  place-content: center;
  position: relative;
  border-width: 3px;
  border-style: solid;
  border-color: #fff;
  border-radius: 9999px;
  width: 56px;
  height: 56px;
  margin-left: -0.75rem;
  margin-right: .75em;
  background-color: $primary; /* Use your actual variable or color value */
  color: white;
  cursor: pointer;
  align-items: center;;
}

.more:before {
  content: attr(data-tooltip);
  position: absolute;
  top: 100%;
  opacity: 0;
  transition: opacity 0.15s ease;
  padding: 5px 10px;
  color: white;
  font-size: 0.75rem;
  border-radius: 8px;
  margin-top: 6px;
  z-index: 1;
  background: black;
  white-space: nowrap;
  z-index: 1060;
}

.more:hover:before {
  opacity: 1;
}