// Import React-Bootstrap's default variables
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

.title{
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    font-size: 3em!important;
}

.title > b{
    color: $primary;
    font-weight: 600;
}

.subtitle{
    color: #4F4F4F;
    font-size: 1.25em!important;
    line-height: 42px;
}

p {
    color: #4F4F4F;
    font-size: 16px;
    line-height: 24px;
}

/* X-Small screens (less than 576px) */
@media only screen and (max-width: 576px) {
    /* Your CSS rules for X-Small screens go here */
    .hero-margin{
        margin: 0!important;
    }

    .title{
        font-size: 2.4em!important;
    }

    .home-header_image-wrapper {
        margin: 0!important;
        padding: 1vh!important;
        width: 100%;
    }
}
  
/* Small screens (576px or more) */
@media only screen and (min-width: 576px) {
    /* Your CSS rules for Small screens go here */
    .hero-margin{
        margin: 0;
    }

    .subtitle{
        font-size: 1.1rem!important;
        line-height: 24px;
    }

    .title{
        font-size: 2.4em!important;
    }
    
    .home-header_image-wrapper {
        margin: 0;
        padding: 0;
    }
}
  
/* Medium screens (768px or more) */
@media only screen and (min-width: 768px) {
    /* Your CSS rules for Medium screens go here */
    .hero-margin{
        margin: 0;
    }
    
    .title{
        font-size: 3em!important;
    }

    .subtitle{
        font-size: 1.1rem!important;
        line-height: 24px;
    }
}
  
/* Large screens (992px or more) */
@media only screen and (min-width: 992px) {
    /* Your CSS rules for Large screens go here */
    .title{
        font-size: 2.25em!important;
    }
}
  
/* Extra large screens (1200px or more) */
@media only screen and (min-width: 1200px) {
    /* Your CSS rules for Extra large screens go here */
    .title{
        font-size: 2.5em!important;
    }
}
  
/* Extra extra large screens (1400px or more) */
@media only screen and (min-width: 1400px) {
    /* Your CSS rules for Extra extra large screens go here */
    .title{
        font-size: 3.3em!important;
        margin-right: .75em;
    }
}

// Hero
img.hero-image {
    border-radius: 1em!important;
}

img.img-overlay{
    position:absolute;
    top:0;
    left:14.5%;
    z-index:3;  
}

.home-header_image-wrapper {
    justify-content: center;
    align-items: center;
    display: flex;
    position: relative;
    margin-bottom: 8.75%;
    padding-top: 5vh;
}

.home-header_pill {
    z-index: 2;
    position: absolute;
    top: auto;
    bottom: -17.5%;
    left: -10%;
    right: auto;
}

.hero-margin{
    margin: 7.5vh 0;
    padding: 0!important;
}