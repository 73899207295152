// Import React-Bootstrap's default variables
@import "~bootstrap/scss/functions";
@import "~bootstrap/scss/variables";

/* Basic editor styles */
.tiptap {
    > * + * {
      margin-top: 0.75em;
    }
  }

.tiptap {
    margin: 0 10%;
    padding: 3em 7.5%;
    min-height: 100%;
    border: 1px rgba(222, 226, 230 ,.5) solid;
    border-top: 0;
    border-bottom: 0;
    background-color: white;
}

/* Default styles for all screen sizes */
.tiptap {
    margin: 0 10%;
    padding: 3em 7.5%;
    min-height: 100%;
    border: 1px rgba(222, 226, 230, .5) solid;
    border-top: 0;
    border-bottom: 0;
    background-color: white;
}

/* Styles for small screens (up to 600px) */
@media only screen and (max-width: 600px) {
    .tiptap {
        margin: 0;
        padding: 2em 5%;
    }
}

/* Styles for medium screens (601px to 1024px) */
@media only screen and (min-width: 601px) and (max-width: 1024px) {
    .tiptap {
        margin: 0 8%;
        padding: 2.5em 7.5%;
    }
}

/* Styles for large screens (1025px and above) */
@media only screen and (min-width: 1025px) {
    .tiptap {
        margin: 0 10%;
        padding: 3em 7.5%;
    }
}

.underline{
    text-decoration: underline;
}

blockquote {
    border-left: 3px solid rgba(#ced4da, 0.5);
    padding-left: 1rem;
}

.hyperlink{
    cursor: pointer;
    text-decoration: underline!important;
    color: #1E43FF;
}

.hyperlink::before {
    content: url('./Icons/BsLink45Deg.svg'); /* Replace 'path/to/link-icon.svg' with the actual path to your SVG file */
    display: inline-block;
    margin-right: 5px; /* Adjust the spacing between the icon and the text as needed */
}

div:has(.tiptap) {
    flex-grow: 1;
    overflow: auto;
}

.tiptap:focus{
    // border: none;
    outline: none;
}
  
/* Placeholder (at the top) */
// .tiptap p.is-editor-empty:first-child::before {
//     content: attr(data-placeholder);
//     float: left;
//     color: #ced4da;
//     pointer-events: none;
//     height: 0;
// }
  
/* Placeholder (on every new line) */
.tiptap .is-empty::before {
    content: attr(data-placeholder);
    float: left;
    color: #ced4da;
    pointer-events: none;
    height: 0;
}

.timestamp{
    background-color: #F8F9FA;
}


/* Give a remote user a caret */
.collaboration-cursor__caret {
    border-left: 1px solid #0d0d0d;
    border-right: 1px solid #0d0d0d;
    margin-left: -1px;
    margin-right: -1px;
    pointer-events: none;
    position: relative;
    word-break: normal;
  }
  
  /* Render the username above the caret */
  .collaboration-cursor__label {
    border-radius: 3px 3px 3px 0;
    color: white;
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    left: -1px;
    line-height: normal;
    padding: 0.1rem 0.3rem;
    position: absolute;
    top: -1.4em;
    user-select: none;
    white-space: nowrap;
  }

.editor {
    height: 100%;
}

.editor__content{
    flex-grow: 1;
}

.tag {
   background-color: rgba($primary, 0.15);

   .selected{
    border-right: solid 3px $primary;
    border-left: solid 3px $primary;
   }
}