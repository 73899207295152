@import "~bootstrap/scss/bootstrap";

.header {
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(8px);
}

aside {
  //position: fixed;
  height: 100%;
  width: 100%;

  .b-example-divider {
    flex-shrink: 0;
    width: 1.5rem;
    height: 100vh;
    background-color: rgba(0, 0, 0, .1);
    border: solid rgba(0, 0, 0, .15);
    border-width: 1px 0;
    box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
  }

  .bi {
    vertical-align: -.125em;
    pointer-events: none;
    fill: currentColor;
  }

  .dropdown-toggle { outline: 0; }

  .nav-flush .nav-link {
    border-radius: 0;
  }

  .btn-toggle {
    display: inline-flex;
    align-items: center;
    padding: .25rem .5rem;
    font-weight: 600;
    color: rgba(0, 0, 0, .65);
    background-color: transparent;
    border: 0;
  }
  .btn-toggle:hover,
  .btn-toggle:focus {
    color: rgba(0, 0, 0, .85);
    background-color: #d2f4ea;
  }

  .btn-toggle::before {
    width: 1.25em;
    line-height: 0;
    content: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='rgba%280,0,0,.5%29' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M5 14l6-6-6-6'/%3e%3c/svg%3e");
    transition: transform .35s ease;
    transform-origin: .5em 50%;
  }

  .btn-toggle[aria-expanded="true"] {
    color: rgba(0, 0, 0, .85);
  }
  .btn-toggle[aria-expanded="true"]::before {
    transform: rotate(90deg);
  }

  .btn-toggle-nav a {
    display: inline-flex;
    padding: .1875rem .5rem;
    margin-top: .125rem;
    margin-left: 1.25rem;
    text-decoration: none;
  }
  .btn-toggle-nav a:hover,
  .btn-toggle-nav a:focus {
    background-color: #d2f4ea;
  }

  .scrollarea {
    overflow-y: auto;
  }

  .fw-semibold { font-weight: 600; }
  .lh-tight { line-height: 1.25; }

  .nav-btn{
    margin-left: 0;
  }

  //.nav-link:not(.active):hover{
  //     background-color: $light!important;
  // }
  //
  //.nav-link-dark:not(.active){
  //  color: $dark;
  //}

  //.nav-item{
  //  margin: .25em 0;
  //}
  //
  //.nav-link{
  //  margin: .25em 0;
  //  display: flex;
  //  align-items: center;
  //}
  //
  //.nav-title{
  //  margin: .25em 0;
  //  display: flex;
  //  align-items: center;
  //  justify-content: space-between;
  //}
}

