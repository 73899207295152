h1{
    font-size: 2em!important;
    font-family: 'Inter', sans-serif;
    font-style: normal;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

@media only screen and (max-width: 576px) {
    h1  {
        font-size: 1.5em!important;
    }
  }

h2{
    font-weight: 700!important;
    font-size: 2.125em!important;
}

.mini{
    font-size: .75em;
}

a {
    text-decoration: none!important;
}

.tall-btn{
    padding-top: .75em!important;
    padding-bottom: .75em!important;
}

#root{
    height: 100%;
}

.App{
    /*min-height:100vh;*/
    /*max-width: 100vw;*/
}

.offset-fixed-navbar{
    padding-top: 62px;
    height: calc(100vh);
}

.offset-fixed-sidebar{
    position: relative;
    left: 248px;
    width: calc(100% - 248px);
    height: calc(100%);
}

@media (max-width: 979px) {
    body {
        padding-top: 0px;
    }
}

.cursor-pointer{
    cursor:pointer;
}

.btn-yellow{
    background-color: #FFC017!important;
    border-color: #000000 !important;
    color: black!important;
    border-width: 2px!important;
    font-weight: 900!important;
}

.heroHeader {
    font-size: 2em;
    font-weight: 600;
}

li{
    font-size: 90%;
}

.navbarLogo{
    font-family: colfax-web, sans-serif;
    font-size: 2em;
    font-weight: 600;
}

/*X-Small devices (portrait phones, less than 576px)*/
/*No media query for `xs` since this is the default in Bootstrap*/

/*Small devices (landscape phones, 576px and up)*/
@media (min-width: 576px) {

}

/*Medium devices (tablets, 768px and up)*/
@media (min-width: 768px) {
    .heroHeader {
        font-size: 3rem!important;
    }
    li{
        font-size: 100%;
    }
}

/*Large devices (desktops, 992px and up)*/
@media (min-width: 992px) {
    .heroHeader {
        font-size: 4rem!important;
    }
}

/*X-Large devices (large desktops, 1200px and up)*/
@media (min-width: 1200px) {
    .heroHeader {
        font-size: 4.5rem!important;
    }
    .subtitle{
        font-size: 1.75rem!important;
    }
}

/*XX-Large devices (larger desktops, 1400px and up)*/
@media (min-width: 1400px) {  }

.unselectable{
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}



.text-purple {
    color: #5053ea!important;
}