// [data-tag-name]::after{
//     content: attr(data-tag-name);
//     font-weight: 600;
//     position: absolute;
//     right: 0;
// }

.tag-name {
    font-weight: 600;
    position: absolute;
    right: 1em;
    cursor: pointer;
    transition: 0.2s;
    transform: translateY(0px);
}

.tag-name:hover {
    width: auto;
    transform: translateY(-12px);
    z-index: 100;
}

div{
    color: rgb(33, 37, 41)rgb(33, 37, 41)
}