.dropdown-toggle::after {
    display: none !important; 
  }

input[type="color"] {
    appearance: none;
    -moz-appearance: none;
    -webkit-appearance: none;
    background: none;
    border: 0;
    cursor: pointer;
}

::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  
::-webkit-color-swatch{
border: 0;
border-radius: 0;
}

  
::-moz-color-swatch,
::-moz-focus-inner{
  border: 0;
}

::-moz-focus-inner{
  padding: 0;
}