.status {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
    background: #ffffff;
    border-radius: 28px;
    padding: 6px 12px;
    user-select: none;
    box-shadow: 0 0 0 1px rgba(31, 41, 55, 0.04), 0 2px 4px rgba(31, 41, 55, 0.06),
      0 4px 16px -2px rgba(31, 41, 55, 0.12);
  }
  
  .status[data-status="connected"] {
    --status-block: #22c55e;
  }
  
  .status[data-status="connecting"],
  .status[data-status="reconnecting"] {
    --status-block: #eab308;
  }
  
  .status[data-status="disconnected"] {
    --status-block: #ef4444;
  }
  
  .statusCircle {
    position: relative;
    background: var(--status-block);
    width: 8px;
    height: 8px;
    border-radius: 9999px;
  }
  
  .statusCircle:before {
    content: "";
    position: absolute;
    display: block;
    top: -1px;
    left: -1px;
    background: var(--status-block);
    width: 10px;
    height: 10px;
    border-radius: 9999px;
    animation: ping 1.5s cubic-bezier(0, 0, 0.2, 1) infinite;
    opacity: 0.4;
  }
  
  .statusText {
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 500;
  }
  
  @keyframes ping {
    75%,
    to {
      transform: scale(2);
      opacity: 0;
    }
  }
  