.avatar {
  display: flex;
  place-content: center;
  position: relative;
  border: 3px solid #fff;
  border-radius: 9999px;
  width: 56px;
  height: 56px;
  margin-left: -0.75rem;
  cursor: pointer;
}

.avatar:before {
  content: attr(data-tooltip);
  position: absolute;
  top: 100%;
  opacity: 0;
  transition: opacity 0.15s ease;
  padding: 5px 10px;
  color: white;
  font-size: 0.75rem;
  border-radius: 8px;
  margin-top: 6px;
  z-index: 1;
  background: black;
  white-space: nowrap;
  z-index: 1060;
}

.avatar:hover:before {
  opacity: 1;
}

.avatar_picture {
  width: 100%;
  height: 100%;
  border-radius: 9999px;
}