.avatar{
    /* margin:0 .5em; */
    background-color: black;
}

.avatarName{
    text-transform: capitalize;
    font-weight: 900;
    color: white;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.avatar:hover{
    background-color: #3c3c3c;
    cursor: pointer;
    transition: background-color .2s
}

/* dropdown open on right side of the menu*/
.dropdown-menu {
    top: 110%;
    right: 0;
}
