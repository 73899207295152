@import '../index.scss';

.text-container {
    position: relative;
    display: inline;
}

.fading-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    opacity: 0;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-image: linear-gradient(to right, rgba(255, 255, 255, 1), rgba(255, 255, 255, 0));
    transition: opacity 0.5s linear;
}

.background-text {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 1;
    transition: opacity 0.5s linear;
}

.blink {
    display: inline-block;
    width: 1ch;
    height: .75em;
    margin-left: .5ch;
    background-color: $primary-dark;
    animation: blink 0.2s linear infinite;
  }
  
  @keyframes blink {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  
textarea:focus, input:focus{
    outline: none;
}