.grid-card {
  max-width: 500px;
  min-height: 187.5px;
  border: #eaeaea solid 1px;

  &:hover {
    cursor: pointer;
    box-shadow: 0 .125rem .25rem rgba(0,0,0,.1)!important;

    .file-card-btn {
      display: block!important;
    }

    .file-card-btn-icon {
      display: block!important;
    }
  }

  .file-card-btn {
    top: -0.5rem;
    right: -0.5rem;
    display: none!important;
    position: absolute;
    border-radius: 500px!important;

    padding: 1rem;
    text-align: center;
    transition: all 0.2s ease-in-out;
  }

  .file-card-btn-icon {
    display: none;
    position: absolute;
    top: 0.5125rem;
    right: 0.5125rem;
    width: 1rem;
    height: 1rem;
    fill: white;
  }
}

.text-underline:hover{
  text-decoration: underline;
}