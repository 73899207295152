@import '~bootstrap/scss/bootstrap.scss';

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  font-style: normal;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

// Override default variables before the import
$primary: #1E43FF;
$primary-dark: #1d3cd9;
$primary-text: #011786;

$secondary: #5053ea;
$tertiary: #ffc6c7;
$marker: rgba(30,67,255,0.21);

$custom-theme-colors:map-merge($theme-colors, (
        "primary": $primary,
        "primary-dark": $primary-dark,
        "primary-text": $primary-text,
        "secondary": $secondary,
        "marker": $marker,
        "tertiary": #ffc6c7,
));

$theme-colors: map-merge($theme-colors, $custom-theme-colors);

$sidebar-width: 250px;
$sidebar-margin-collapsed: 100px;
$sidebar-width-collapsed: 100px;
$sidebar-slide-speed: .25s;

.btn-white {
  background-color: white!important;
  color: black!important;
}

.text-primary-dark{
  color: $primary-text;
}

.bg-light{
  background-color: #F4F9FC;
}

.text-black{
  color: black;
}

.text-gray{
  color: #b1b1b1;
}

.dropdown-menu > li > a:hover {
  background-image: none;
  background-color: $primary;
}

.btn-circle {
  position: relative;
  width: 36px;
  height: 36px;
  border-radius: 50%!important;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1em;
}

.rounded-top {
  border-radius: var(--bs-border-radius) !important;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.border-gray {
  border: #eaeaea solid 1px;
}

.btn-white:hover{
  background-color: lightgray!important;
}

.btn-white:active{
  border-color: rgba(255, 255, 255, 0) !important;
}


.btn-flat {
  background-color: purple;
  color: white;
}

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';

#page-content-wrapper{
  width: 100%;
}


.tab-content {
  height: 100%;
}

.tab-pane {
  padding: 0;
}

.low-highlight {
  background: linear-gradient(to bottom,
          transparent 82%,
          $tertiary 18%);
  display:inline;
}

.text-marked {
  padding: 0 .005em;
  border-radius: 3px;
  background: linear-gradient(to bottom,
          transparent 85%,
          $tertiary 15%);
  font-weight: normal!important;
}

.text-truncate {
  text-wrap: wrap!important;
  text-overflow: ellipsis!important;
  overflow: hidden!important;
}



// Scrollbar
/* ===== Scrollbar CSS ===== */
// TODO: only apply to menu bar
/* Firefox */
// * {
//     scrollbar-width: auto;
//     scrollbar-color: #c9c9c9 #ffffff;
//   }
  
//   /* Chrome, Edge, and Safari */
//   *::-webkit-scrollbar {
//     width: 8px;
//   }
  
//   *::-webkit-scrollbar-track {
//     background: #ffffff;
//   }
  
//   *::-webkit-scrollbar-thumb {
//     background-color: #c9c9c9;
//     border-radius: 9999px;
//     border: 4px solid #ffffff;
//   }