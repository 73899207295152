div [data-tippy-root] {
    animation: slide-up 0.2s ease;
}

@keyframes slide-up {
    0% {
        opacity: 0;
        padding-bottom: 10px;
    }
    100% {
        opacity: 1;
        padding-bottom: 0px;
    }
}