input[type="file"] {
    display: none;
}

label {
    width: 100%;
}

.file-upload {
    display: inline-block;
    cursor: pointer;
}

.border-dotted{
    border-style: dashed!important;
    border-width: 2px;
}

.border-gray{
    border-color: rgb(206, 212, 218);
}
